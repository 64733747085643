<script setup lang="ts">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { computed } from 'vue';
	import { MzButton, MzTag } from '@monizze/monizze-components';
	import { faPowerOff, faCircleCheck, faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
	import { useI18n } from 'vue-i18n';
	import { DeviceDetailedInfo } from '@/types/Responses/DeviceDetailedInfo.ts';
	import { LoginDevice } from '@/types/Responses/LoginDevice.ts';

	const { t } = useI18n();
	const props = defineProps<{
		device: DeviceDetailedInfo;
	}>();

	defineEmits<{
		'show-session': [id: LoginDevice];
		logout: [];
	}>();

	const canLogout = computed(() => {
		return props.device.sessionDevices.find((session: LoginDevice) => session.active && !session.current) !== undefined;
	});
</script>

<template>
	<div class="flex max-lg:flex-col gap-8 lg:gap-16">
		<div class="max-lg:w-full">
			<div class="pl-0 lg:pl-4 body-intro font-semibold">
				<div class="flex items-start">
					<div>
						<span class="text-center w-[24px] pr-3"><font-awesome-icon :icon="['fas', device.type]" class="icon-24px" /></span>
						<template v-if="device.device">
							{{ device.device }}
						</template>
						<template v-else>
							{{ t('user.security.device.unknown') }}
						</template>
					</div>
				</div>
			</div>
			<div class="lg:pl-4 lg:mt-8 lg:items-center max-lg:hidden">
				<mz-button
					v-if="canLogout"
					class="self-start w-full"
					type="tertiary"
					:label="t('user.security.device.history.logout')"
					:action="() => $emit('logout')"
					:width-auto="true"
					icon-position="left"
					:icon="faPowerOff"
				/>
			</div>
		</div>
		<div class="flex-grow">
			<div class="flex flex-col">
				<template v-for="(detail, key) in device.sessionDevices" :key="key">
					<div class="flex max-lg:flex-col justify-evenly text-left py-2" :class="{ 'border-t-[1px]': key !== 0 }">
						<div class="text-left lg:w-1/2">
							<p>{{ detail.date }}</p>
							<p v-if="detail.city">{{ detail.city.en }}</p>
							<p v-if="detail.country">{{ detail.country.en ?? '' }}</p>
						</div>
						<div class="text-left lg:w-1/2">
							<div class="max-lg:flex max-lg:justify-between items-center w-full">
								<div>
									<p>{{ detail.os }}</p>
									<p>{{ detail.browser }}</p>
									<mz-tag
										v-if="detail.current"
										type="success"
										:label="t('user.security.device.history.detail.current')"
										size="medium"
										:icon="faCircleCheck"
									/>
									<mz-tag
										v-else-if="detail.active"
										type="info"
										:label="t('user.security.device.history.detail.active')"
										size="medium"
										:icon="faSquareInfo"
									/>
								</div>
								<button class="lg:hidden relative pt-0 -top-4" @click="$emit('show-session', detail)">
									<span class="text-center w-[24px] pr-3"><font-awesome-icon :icon="['fas', 'fa-chevron-right']" class="icon-24px" /></span>
								</button>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
